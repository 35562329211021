@import "styles/responsive";

packex-cart-price-overview {
  display: block;
  padding: 1.5rem;
  width: 100%;
  border-radius: 0.25rem;
  background: var(--white);

  @include media-breakpoint-down(lg) {
    width: 100%;
  }

  .header {
    border-bottom: 1px solid var(--grey-medium);
  }
}

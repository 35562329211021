packex-cart-voucher {
  .voucher-form {
    .voucher-form-field {
      flex: 1;
    }

    .packex-button {
      height: 2.5rem !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    &::before {
      content: unset !important;
    }
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    top: initial !important;
    position: relative !important;
    padding: 0 !important;
  }
}

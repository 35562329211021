packex-cart-total {
  .total {
    padding: 1rem 1.5rem;
  }

  ngx-skeleton-loader {
    .loader {
      height: 100% !important;
    }
  }
}

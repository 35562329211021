@import 'styles/responsive';

packex-cart-modal {
  packex-inventory-item-dieline-svg {
    height: 6.5rem;
  }

  packex-modal-cart-item-select {
    width: 220px;
  }
}

@import "styles/responsive";

packex-cart-item-list, packex-cart-order-summary {
  width: 100%;

  .product-wrapper {
    packex-cart-product {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 2rem 1.5rem 2.5rem;
      border-radius: 4px;
      //box-shadow: 0 1px 3px 0 rgba(52, 51, 101, 0.16), 0 1px 20px 0 rgba(52, 51, 101, 0.14);
      background-color: var(--white);

      .product-header {
        border-bottom: 1px solid var(--grey-medium);
      }

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }

    @include media-breakpoint-up(md) {
      packex-cart-product {
        width: 100%;
      }
    }
  }
}

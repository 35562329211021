.faq {
  margin: 1rem 0;

  a {
    color: var(--secondary-medium);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

import { Component, input } from '@angular/core';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal.pipe';
import { LoyaltyStatusDto } from '@modules/club/loyalty-club.types';
import { NgOptimizedImage } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { Router } from '@angular/router';

@Component({
  selector: 'packex-status-order-banner',
  styleUrls: ['./status-order-banner.component.scss'],
  templateUrl: './status-order-banner.component.html',
  imports: [
    FormatDecimalPipe,
    NgOptimizedImage,
    IconComponent,
    TranslocoPipe,
    ButtonComponent,
  ],
})
export class StatusOrderBannerComponent {
  status = input<LoyaltyStatusDto | null>();
  additionalPoints = input<number | null>();

  constructor(private readonly router: Router) {}

  get nextLevelPercentage() {
    if (!this.status()?.nextStatus) {
      return 0;
    }

    const points = this.status()!.points + (this.additionalPoints() ?? 0);

    return points / this.status()!.nextStatus!.minPoints;
  }

  get totalPoints() {
    return (this.status()?.points ?? 0) + (this.status()?.pendingPoints ?? 0);
  }

  get missingPointsForNextLevel() {
    if (!this.status()?.nextStatus) {
      return 0;
    }

    return this.status()!.nextStatus!.minPoints - this.totalPoints;
  }

  goToLoyaltyClub() {
    this.router.navigate(['/heldenclub']);
  }
}

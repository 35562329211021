packex-modal-cart-item {
  display: block;
}

.cart-item {
  .disabled {
    opacity: 30%;
  }
}

.cart-item {
  border-bottom: 1px solid var(--grey-medium);
  padding: 0.5rem 0;

  &.first {
    border-top: 1px solid var(--grey-medium);
  }
}

.services-container {
  position: relative;
  left: -0.5rem;

  .mat-checkbox-inner-container {
    margin-top: .2rem;
  }
}

.input.amount-input {
  text-align: center;
}

.service-completed-label {
  &.mat-mdc-chip.mat-mdc-standard-chip {
    height: 1.25rem !important;
    background-color: var(--white) !important;
    border: 1px solid var(--primary-dark) !important;

    .mdc-evolution-chip__checkmark {
      color: var(--primary-dark) !important;
    }
  }
}

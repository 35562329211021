packex-accordion {
  .mat-expansion-indicator::after {
    border-style: solid;
    border-width: 0 1px 1px 0;
    padding: 5px;
    transform: rotate(45deg);
    vertical-align: middle;
    display: flex;
  }

  .mat-expansion-panel-header {
    padding: 0 1rem 0 0;
  }

  .mat-expansion-panel-body {
    padding: 1.5rem 0;
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-accordion .mat-expansion-panel {
    border-bottom: 1px solid var(--grey-medium);
    border-radius: 0 !important;
    background: transparent;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { getPaymentMethodTranslation } from '@app/utils/cart.helper';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CartOrder } from '@shared/models/cart-order';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { PageComponent } from '../../../shared/components/ui/page/page.component';
import { NgIf, NgFor } from '@angular/common';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { CardComponent } from '../../../shared/components/ui/card/card.component';
import { AddressComponent } from '../../../shared/components/address/address.component';
import { CarouselComponent } from '../../../shared/components/carousel/carousel.component';
import { AccordionComponent } from '../../../shared/components/accordion/accordion.component';
import { AccordionStepComponent } from '../../../shared/components/accordion/accordion-step/accordion-step.component';
import { FormatPricePipe } from '@shared/pipes/format-price.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { StatusOrderBannerComponent } from '@modules/club/components/status-order-banner/status-order-banner.component';
import { UserService } from '@app/core/services/user.service';

@UntilDestroy()
@Component({
  selector: 'packex-order-received',
  templateUrl: './order-received.component.html',
  styleUrls: ['./order-received.component.scss'],
  imports: [
    PageComponent,
    NgIf,
    IconComponent,
    CardComponent,
    AddressComponent,
    NgFor,
    CarouselComponent,
    AccordionComponent,
    AccordionStepComponent,
    FormatPricePipe,
    TranslocoPipe,
    StatusOrderBannerComponent,
  ],
})
export class OrderReceivedComponent implements OnInit {
  order?: CartOrder;
  billingEmails: string[] = [];
  deliveryEmails: string[] = [];

  getPaymentMethodTranslation = getPaymentMethodTranslation;

  constructor(
    private readonly cartService: CartService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly gtm: GoogleTagService,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.load().subscribe();
    this.route.params.pipe(untilDestroyed(this)).subscribe(({ id }) => {
      this.cartService.getOrder(id).subscribe((order: CartOrder) => {
        this.order = order;

        this.gtm.purchase(this.order).then(() => {});
      });
    });

    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForBillingEmails,
        this.invoiceRecipientEmails,
      )
      .subscribe((emails) => (this.billingEmails = emails));

    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForDeliveryEmails,
        this.statusUpdateEmails,
      )
      .subscribe((emails) => (this.deliveryEmails = emails));
  }

  get invoiceRecipientEmails(): string[] {
    return (this.order?.invoiceRecipientEmails || []).concat(
      this.billingEmails,
    );
  }

  get statusUpdateEmails(): string[] {
    return (this.order?.statusUpdateEmails || []).concat(this.deliveryEmails);
  }

  get loyaltyStatus() {
    return this.userService.user?.loyaltyStatus;
  }

  get additionalPoints() {
    return Math.ceil(this.order?.totalNetPrice ?? 0);
  }

  get isLoyaltyEnabled() {
    return this.userService.user?.loyaltyStatus.disabled !== true;
  }
}
